
const userFormToggle = () => {
  if (document.getElementById('edit-profile')) {
    const userBtn = document.getElementById('edit-profile');
    const userForm = document.getElementById('profile-form');
    const userCloseBtn = document.getElementById('close-form-btn');
    userBtn.addEventListener('click', () => {
      userForm.classList.remove('hidden')
    });
    userCloseBtn.addEventListener('click', () => {
      userForm.classList.add('hidden');
    })
  }
};

export { userFormToggle };