const homeFlex = () => {
  const container = document.getElementsByClassName('home-page__djs-container')[0];
  const items = document.getElementsByClassName('home-page__dj');
  const item = items[0];
  const itemMargin = () => {
    const splitStyle = getComputedStyle(item).margin.split(' ');
    return parseInt(splitStyle[splitStyle.length - 1]) * 2
  }

    if (container && items ) {
      const removables = Array.from(document.getElementsByClassName('removable'));
      if (removables && removables.length > 0) {
        removables.forEach(el => el.remove())
      }
      const itemsOnRow = Math.floor(container.offsetWidth / (item.offsetWidth + itemMargin()));
      const colomns = Math.ceil(items.length / itemsOnRow);
      const itemsToAdd = (colomns * itemsOnRow) % items.length;

      if (colomns <= 1) {
        return
      } else {
        let i = 1;
        while (i <= itemsToAdd) {
          let newDiv = document.createElement('div');
          newDiv.classList.add('home-page__dj');
          newDiv.classList.add('removable');
          newDiv.style.boxShadow = 'none';
          container.appendChild(newDiv);
          i++
        }
      }
    }
  }

export  { homeFlex };