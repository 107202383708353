const openEditMessageForm = () => Array.from(document.getElementsByClassName('message-open-edit-form'));
const editMessageForm = () => Array.from(document.getElementsByClassName('message-edit-form'));
const closeEditMessageForm = () => Array.from(document.getElementsByClassName('message-close-btn'));

const openEditForm = () => {
  if (openEditMessageForm()) {
    openEditMessageForm().forEach((el, i) => el.addEventListener('click', () => {
      editMessageForm()[i].style.display = 'block';
    }))
  }
};

const closeEditForm = () => {
  if(closeEditMessageForm()) {
    closeEditMessageForm().forEach((el, i) => el.addEventListener('click', () => {
      editMessageForm()[i].style.display = 'none';
    }))
  }
};

export { openEditForm, closeEditForm };