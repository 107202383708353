import consumer from "./consumer";
import { setTheMessagesColors } from "../packs/bookingPage/setTheMessagesColors";
import { setEditOptions } from "../packs/message-packs/setEditOptions";


const initChatroomCable = () => {
  const messagesContainer = document.getElementById('chatroom-messages');
  const findId = (incomingData) => incomingData.split(' ').join('').split('-')[1].replace(/\D/g, '') * 1
  if (messagesContainer) {
    const id = messagesContainer.dataset.chatroomId;
    consumer.subscriptions.create({ channel: "ChatroomChannel", id: id }, {
      received(data) {
        const id = findId(data);
        const message = document.getElementById(`message-${id}`)
        const messages = Array.from(document.querySelectorAll('#chatroom-messages .message'));
        const ids = messages.map(el => findId(el.outerHTML))
        if (ids.includes(id)) {
          message.innerHTML = data.split('h>')[1];
          setEditOptions(id);
        } else {
          messagesContainer.insertAdjacentHTML('beforeend', data);
          setEditOptions(id);
        }
        setTheMessagesColors();
      }
    });
  }
}


export { initChatroomCable };