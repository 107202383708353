const setBookingDetails = () => {
  const updateBtn = document.getElementById('update-booking');
  const bookingExtraForm = document.getElementById('booking-extra-requirements-form');
  const closeForm = document.getElementById('close-form');

  if (updateBtn) {
    updateBtn.onclick = () => bookingExtraForm.style.display = 'flex';
    closeForm.onclick = () => bookingExtraForm.style.display = 'none';
  }
}

export { setBookingDetails };