const setIframesHeight = () => {
  if (window.innerWidth > 577) {
    if (document.getElementById('dj-listen-header')) {
      const listenHeader = document.getElementById('dj-listen-header');
      const listenItems = Array.from(document.getElementsByClassName('dj-listen-item'));
      listenItems.forEach(el => el.style.height = `${listenHeader.offsetHeight}px`);
    }
  }
}

export { setIframesHeight };