import { userFormToggle } from "./userShowPage/userFormToggle";
import { bookingFormToggle } from "./userShowPage/bookingFormToggle";
import { socialMediaWidth } from "./userShowPage/socialMediaWidth";
import { setHeightOfArtistPhotos } from "./userShowPage/setHeightOfArtistPageComponents";
import { djChangeMainPhoto } from './userShowPage/djChangeMainPhoto';
import { setDjNameFontSize } from "./userShowPage/djName";
import { setIframesHeight } from "./userShowPage/iframesHeight";
import { onResizeBehaviour } from "./userShowPage/onResizeBehaviour";
import { homeFlex } from "./homePage/homeFlex";
import { setTheBookingPrice } from "./bookingPage/setThePrice";
import { setTheMessagesColors } from "./bookingPage/setTheMessagesColors";
import { initChatroomCable } from '../channels/chatroom_channel';
import { setBookingDetails } from '../packs/bookingPage/setBookingDetails';
import {toggleArtistPhotoForm, toggleArtistPhotoEditForm} from '../packs/userShowPage/toggleArtistPhotoForm';
import {openEditForm, closeEditForm} from '../packs/message-packs/openEditForm';
import {messageHistory} from '../packs/message-packs/messageHistory';
import editCompanyInfo from '../packs/editCompanyInfo';

document.addEventListener('turbolinks:load', () => {
  onResizeBehaviour();
  userFormToggle();
  bookingFormToggle();
  socialMediaWidth();
  setDjNameFontSize();
  djChangeMainPhoto();
  setHeightOfArtistPhotos();
  homeFlex();
  setIframesHeight();
  setTheBookingPrice();
  setTheMessagesColors();
  initChatroomCable();
  setBookingDetails();
  toggleArtistPhotoForm();
  toggleArtistPhotoEditForm();
  openEditForm();
  closeEditForm();
  messageHistory();
  editCompanyInfo();
});
