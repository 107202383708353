const setDjNameFontSize = () => {
  if (document.getElementById('dj-name')) {
    const djName = document.getElementById('dj-name');
    const listen = document.getElementById('dj-listen-header');
    const event = document.getElementById('dj-bookings-header');

    const items = [djName, listen, event];

    const itemsChange = (size) => items.forEach(it => it ? it.style.fontSize = size : null);

    if (window.innerWidth < 577) {
      if (djName.innerText.length <= 8) {
        itemsChange('40px');
      } else if (djName.innerText.length > 8) {
        itemsChange('36px');
      }
    } else if (window.innerWidth > 577 && window.innerWidth <= 900) {
        if (djName.innerText.length <= 10) {
          itemsChange('70px');
        } else if (djName.innerText.length > 10) {
          itemsChange('60px');
        }
      } else if (window.innerWidth > 900) {
          if (djName.innerText.length <= 10) {
            itemsChange('80px');
          } else if (djName.innerText.length > 10) {
            itemsChange('70px');
          }
      }
    }
  }


export { setDjNameFontSize };