const socialMediaWidth = () => {
  const smWidth = document.getElementById("dj-social-media");
  const bioCont = document.getElementById("dj-profile-bio");
  if (smWidth && bioCont) {
    const setWidth = (i) => (smWidth.innerHTML.split("</b>").length - 1) * i;
      smWidth.style.width = `${setWidth(4)}%`;
  }
};

export { socialMediaWidth };
