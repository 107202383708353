const setHeightOfArtistPhotos = () => {
  // setTimeout(
    // () => {
        if (document.getElementsByClassName('dj-photo') && document.getElementById('dj-main-photo')) {
          const photos = Array.from(document.getElementsByClassName('dj-photo'));
          const mainPhoto = document.getElementById('dj-main-photo');
          const bioSection = document.getElementById('dj-profile-bio');
          const djProfile = document.getElementsByClassName('dj-profile__photos')[0];

          photos.forEach(p => p.style.height = `${p.offsetWidth}px`);
          mainPhoto.style.height = `${mainPhoto.offsetWidth * 1.4166}px`;
          bioSection.style.maxHeight = `${djProfile.offsetHeight}px`;
        }
      // }, 400
  // ) 
}


export { setHeightOfArtistPhotos };