const infos = Array.from(document.getElementsByClassName('user-info-company'));
const forms = Array.from(document.getElementsByClassName('user-add-company'));
const formBtns = Array.from(document.getElementsByClassName('edit-company-form'));
const closingBtns = Array.from(document.getElementsByClassName('closing-btn'));

const editCompanyInfo = () => {
  if (infos) {
    formBtns.forEach((el, i) => el.addEventListener('click', () => forms[i].classList.remove('hidden')));
    closingBtns.forEach((el, i) => el.addEventListener('click', () => forms[i].classList.add('hidden')));
  }
}

export default editCompanyInfo;