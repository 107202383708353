const styleLeft = (el) => {
  el.style.backgroundColor = '#383637';
  el.style.color = '#706e6f';
  el.style.alignSelf = 'flex-start';
  el.setAttribute('setStyle', 'left')
}
const styleRight = (el) => {
  el.style.backgroundColor = '#706e6f';
  el.style.color = '#383637';
  el.style.alignSelf = 'flex-end';
  el.setAttribute('setStyle', 'right')
}

const setTheMessagesColors = () => {
  const messages = Array.from(document.getElementsByClassName('message'));
  messages.forEach((el, i) => {
    const preMessage = messages[i - 1];
    const elAuthorId = el.attributes[2].value;
    const elPreAuthorId = i != 0 ? messages[i - 1].attributes[2].value : null
    if (i % 2 === 0) {
      styleLeft(el);
    } else {
      styleRight(el);
    }
    if (elAuthorId === elPreAuthorId) {
      preMessage.attributes[8].value === 'left' ? styleLeft(el) : styleRight(el);
    } else if (elAuthorId !== elPreAuthorId) {
        if (elPreAuthorId) {
          preMessage.attributes[8].value === 'left' ? styleRight(el) : styleLeft(el);
        } else {
          styleLeft(el)
        }
    }
  });
};

export { setTheMessagesColors };