const toggleArtistPhotoForm = () => {
  const openForm = document.getElementById('open-add-photo-form');
  if (openForm) {
    const closeForm = document.getElementById('close-add-photo-form');
    const photoForm = document.getElementById('user-add-photo-form');
    openForm.addEventListener('click', () => {
      photoForm.style.display = 'block';
    });
    closeForm.addEventListener('click', () => {
      photoForm.style.display = 'none';
    })
  }
}

const toggleArtistPhotoEditForm = () => {
  const openEditForms = Array.from(document.getElementsByClassName('open-edit-photo-form'));
  const editForms = Array.from(document.getElementsByClassName('user-edit-photo-form'));
  const closeForms = Array.from(document.getElementsByClassName('close-edit-photo-form'));
  if (openEditForms) {
    openEditForms.forEach((el, i) => el.addEventListener('click', () => {
      editForms[i].style.display = 'block';
      editForms.filter((ef, efi) => efi != i).forEach(ef => ef.style.display = 'none')
    }))
    closeForms.forEach((el, i) => el.addEventListener('click', () => { 
      editForms[i].style.display = 'none' 
    }))
  }
}


export { toggleArtistPhotoForm, toggleArtistPhotoEditForm };