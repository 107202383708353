const bookingFormToggle = () => {
  if (document.getElementById('request-booking')) {
    const bookingBtn = document.getElementById('request-booking');
    const bookingForm = document.getElementById('dj-booking-form');
    const bookingCloseBtn = document.getElementById('close-booking-form');
    bookingBtn.addEventListener('click', () => {
      bookingForm.classList.remove('hidden')
    })
    bookingCloseBtn.addEventListener('click', () => {
      bookingForm.classList.add('hidden');
    })
  }
}

export { bookingFormToggle };