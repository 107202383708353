import { setDjNameFontSize } from "./djName";
import { setHeightOfArtistPhotos } from "./setHeightOfArtistPageComponents";
import { setIframesHeight } from "./iframesHeight";
import { homeFlex } from "../homePage/homeFlex";

const onResizeBehaviour = () => {
  window.onresize = () => {
    setDjNameFontSize();
    setHeightOfArtistPhotos();
    setIframesHeight();
    homeFlex();
  }
}

export { onResizeBehaviour };