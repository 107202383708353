const messageHistory = () => {
  const messages = Array.from(document.getElementsByClassName('message-history-btn'));
  const histories = Array.from(document.getElementsByClassName('message-history'));
  if (messages) {
    messages.forEach((m, i) => m.addEventListener('click', () => {
      if (histories[i].style.display == 'block') {
        histories[i].style.display = 'none';
      } else {
        histories[i].style.display = 'block';
      }
    }));
  }
}

export {messageHistory};