const setEditOptions = (id) => {
  const message = document.getElementById(`message-${id}`);
  // const author = message.getAttribute('data-author');
  // const currentUser = document.getElementById('booking-container').getAttribute('data-current_user');
  // const isUserAdmin = document.getElementById('booking-container').getAttribute('data-is_admin');
  // const isHiddenByAdmin = message.getAttribute('data-hidden_by_admin');
  // const isInvisible = message.getAttribute('data-invisible');

  const editButton = message.getElementsByClassName('fa-edit')[0];
  const historyButton = message.getElementsByClassName('fa-history')[0];
  const invisibleButton = message.getElementsByClassName('fa-eye-slash')[0];
  const visibleButton = message.getElementsByClassName('fa-eye')[0];
  const destroyButton = message.getElementsByClassName('fa-trash-alt')[0];



  editButton ? editButton.setAttribute('hidden', '') : null;
  historyButton ? historyButton.setAttribute('hidden', '') : null;
  destroyButton ? destroyButton.setAttribute('hidden', '') : null;
  invisibleButton ? invisibleButton.setAttribute('hidden', '') : null;
  visibleButton ? visibleButton.setAttribute('hidden', '') : null;

}

export {setEditOptions};