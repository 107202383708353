const setTheBookingPrice = () => {
  const setBtn = document.getElementById("set-the-price__btn");
  const setForm = document.getElementById("set-the-price__form");
  const closeBtn = document.getElementById("set-the-price__close");
  if (setBtn) {
    setBtn.onclick = () => setForm.classList.remove('hidden');
    closeBtn.onclick = () => setForm.classList.add('hidden');
  }
}

export {setTheBookingPrice};