const djChangeMainPhoto = () => {
  if (document.getElementsByClassName('dj-container')) {
    const mainPhoto = document.getElementById('dj-main-photo');
    const images = document.getElementsByClassName('dj-photo');
    Array.from(images).forEach(img => {
      img.addEventListener('click', () => {
        mainPhoto.src = img.src
      })
    })
  }
}

export { djChangeMainPhoto };